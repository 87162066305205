import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Spinner } from "react-bootstrap"
import ReactPaginate from "react-paginate"

import PostCard from "./post-card"
import Pagination from "./pagination"
import "./post-list.css"

const PostList = props => {
  const mobileDeviceSize = 480
  const posts = props.posts
  const categories = props.categories
  const [catName, setCatName] = useState("All")

  const [currentItems, setCurrentItems] = useState(null)
  const [currentItemsMobile, setCurrentItemsMobile] = useState(null)
  const [pageCount, setPageCount] = useState([1])
  const [currentPage, setCurrentPage] = useState(1)
  const [allPosts, setAllPosts] = useState(null)
  const [itemOffset, setItemOffset] = useState(0)
  const [isCurrPageReset, setIsCurrPageReset] = useState(false)

  const pageWidth = typeof window !== "undefined" && window.innerWidth
  const [isResizeListenerEnabled, setIsResizeListenerEnabled] = useState(false)
  const [currWidth, setCurrWidth] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(pageWidth <= 480)

  const [isVisible, setIsVisible] = useState(false)
  const [postCount, setPostCount] = useState(0)

  const [itemOffsetted, setItemOffsetted] = useState(0)
  const [currentItemed, setCurrentItemed] = useState(null)
  const [pageCounted, setPageCounted] = useState(0)

  const containerRef = useRef(null)
  const afterBlogsSec =
    typeof document !== "undefined"
      ? document.querySelectorAll(
          ".techblog-section, #quotes, .site-footer, #copyrights"
        )
      : []
  const getFilteredPost = data => {
    return data.filter(data => {
      if (catName === "All") return data
      return data.node.categories.some(dt => dt.title === catName)
    })
  }
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
    delay: 400,
  }
  const cbObserver = async entries => {
    const [entry] = entries
    const currPosts = getFilteredPost(posts)
    const count = postCount + 1
    const sliceAllItems = currPosts.slice(0, count)
    ;[].forEach.call(afterBlogsSec, function(el) {
      el.classList.remove("d-none")
    })
    if (count <= currPosts.length) {
      ;[].forEach.call(afterBlogsSec, function(el) {
        el.classList.add("d-none")
      })
    }
    if (currPosts.length < count) return
    setIsVisible(entry.isIntersecting)
    setAllPosts(currPosts)
    setCurrentItemsMobile(sliceAllItems)
    if (isVisible) {
      setPostCount(count)
      setIsVisible(false)
    }
  }

  const scrollToPostListRef = useRef(null)
  // Invoke when user click to request another page.
  const handlePageClick = page => {
    const newOffset = ((page - 1) * 9) % allPosts.length
    console.log(
      `User requested page number ${page}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
    setCurrentPage(page)
    setIsCurrPageReset(false)
  }

  const handleWidthResize = width => {
    if (width <= mobileDeviceSize) {
      setIsMobileDevice(true)
    } else {
      setIsMobileDevice(false)
    }
    setCurrWidth(width)
  }

  useEffect(() => {
    const currPosts = getFilteredPost(posts)

    if (!isResizeListenerEnabled) {
      setIsResizeListenerEnabled(true)
      if (typeof window !== "undefined") {
        window.addEventListener("resize", () =>
          handleWidthResize(window.innerWidth)
        )
      }
    }

    if (pageWidth > mobileDeviceSize) {
      setIsVisible(false)
      ;[].forEach.call(afterBlogsSec, function(el) {
        el.classList.remove("d-none")
      })

      const offSet = currentPage === 1 ? 0 : isCurrPageReset ? 0 : itemOffset
      const endOffset = offSet + 9
      console.log(`Loading items from ${itemOffset} to ${endOffset}`)
      setAllPosts(currPosts)
      setCurrentItems(currPosts.slice(offSet, endOffset))
      setPageCount(() => {
        const count = Math.ceil(currPosts.length / 9)
        const pages = []
        for (var i = 1; i <= count; i++) {
          pages.push(i)
        }
        return pages
      })
      setItemOffset(endOffset)
      if (isCurrPageReset) setCurrentPage(1)
    }

    if (
      typeof window !== "undefined" &&
      window.innerWidth <= mobileDeviceSize
    ) {
      const count = postCount + 1
      const observer = new IntersectionObserver(cbObserver, options)
      const unobserveRef = () => {
        if (containerRef.current) observer.unobserve(containerRef.current)
      }

      ;[].forEach.call(afterBlogsSec, function(el) {
        el.classList.add("d-none")
      })

      if (containerRef.current) observer.observe(containerRef.current)

      if (currPosts.length < count) return () => unobserveRef()
      setAllPosts(currPosts)
      setCurrentItemsMobile(currPosts.slice(0, count))
      if (isVisible) {
        setPostCount(count)
        setIsVisible(false)
      }

      return () => unobserveRef()
    }
    const endOffsetted = itemOffsetted + 9
    setCurrentItemed(currPosts.slice(itemOffsetted, endOffsetted))
    setPageCounted(Math.ceil(currPosts.length / 9))
  }, [
    currentPage,
    catName,
    containerRef,
    isVisible,
    postCount,
    currWidth,
    itemOffsetted,
  ])

  const handlePageClicked = event => {
    const newOffset = (event.selected * 9) % getFilteredPost(posts).length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffsetted(newOffset)
  }

  return (
    <div className="position-relative">
      {pageWidth > mobileDeviceSize ? (
        <ul className="style-unstyled cat-nav text-center blog-categories">
          <li className="d-inline">
            <button
              onClick={() => {
                setCatName("All")
                setIsCurrPageReset(true)
                setPostCount(0)
                scrollToPostListRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }}
            >
              All
            </button>
          </li>
          {categories.map((tag, i) => (
            <li className="d-inline">
              <button
                onClick={() => {
                  setCatName(tag.fieldValue)
                  setIsCurrPageReset(true)
                  setPostCount(0)
                  scrollToPostListRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }}
              >
                {tag.fieldValue}
              </button>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      <Container style={{ paddingBottom: "6rem" }}>
        <Row className="justify-content-center" ref={scrollToPostListRef}>
          {currentItemed?.map(({ node, i }) => {
            return <PostCard {...node} />
          })}
          {!currentItemsMobile
            ? ""
            : !isMobileDevice
            ? ""
            : currentItemsMobile.map(({ node, i }) => {
                return <PostCard {...node} />
              })}
        </Row>
        {/* {pageWidth <= 480
          ? ""
          : currentItemed && (
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            )} */}
        <ReactPaginate
          breakLabel="..."
          nextLabel={<i className="fa fa-angle-right paginate-arrow"></i>}
          onPageChange={handlePageClicked}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCounted}
          previousLabel={<i className="fa fa-angle-left paginate-arrow"></i>}
          renderOnZeroPageCount={null}
          containerClassName="new-pagination"
        />
      </Container>
      <div ref={containerRef} className="d-flex justify-content-center mb-3">
        {pageWidth <= 480 &&
        currentItemsMobile &&
        currentItemsMobile.length > postCount ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default PostList
