import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./news.css"

import LongForm from "../components/longform/longform"
import PostList from "../components/news-list/post-list"
import CaseStudySection from "../components/header/header-casestudy-section"
import Cases from "../components/cases"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const NewsListPage = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allSanityPost.edges
  const categories = data.allSanityPost.group

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title="Our Blog | Quandary Consulting Group"
        description="Discover our expert insights and thought leadership on AI, innovation, and rapid development for businesses  redefining how work gets done."
      />
      <div className="news-list-page">
        <div
          id="pagetitle"
          className="page-intro page-intro-news"
          style={{ paddingTop: "6rem", paddingBottom: "4rem" }}
        >
          <div className="container">
            <div className="page-title-inner text-center">
              <h5 className="text-white font-weight-bold">The Quandary Blog</h5>
              <h1 className="page-title text-white">
                Discover how we’re helping businesses large and small turn ideas
                into innovative solutions in weeks, not months.
              </h1>
            </div>
          </div>
        </div>
        <PostList posts={posts} categories={categories} />
        <div className="relative" style={{ zIndex: 1, position: "relative" }}>
          <CaseStudySection />
          <CaseComponent dataSrc={data.allSanityCaseStudy} />
        </div>
        {/* <LongForm />
        <section id="quotes" style={{ padding: "5rem 0" }}>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="text-light-blue font-weight-bold">
                  Transform How Your Business Functions
                </h5>
                <h2 className="font-weight-medium">
                  Improve Workflows. Reduce Bottlenecks. Give Your Team More
                  Time and Your Business More Resources. Remain Competitive.
                </h2>
                <div className="text-center">
                  <a
                    className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
                    href="/contact"
                    alt="Reviews"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Speak to a Technology Consultant
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <div className="py-5 bg-white text-center">
          <Container>
            <h4 className="m-0">
              Custom workflow solutions crafted by a team who knows what you
              need
            </h4>
          </Container>
        </div> */}
      </div>
    </Layout>
  )
}

export default NewsListPage

export const pageQuery = graphql`
  query pageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 580
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          _createdAt
          publishedAt
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          mainImage {
            asset {
              url
            }
          }
          author {
            name
            image {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
          slug {
            current
          }
          body
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            title
            author {
              name
              image {
                asset {
                  gatsbyImageData(
                    fit: FILL
                    placeholder: BLURRED
                    sizes: ""
                    layout: CONSTRAINED
                  )
                }
              }
            }
            publishedAt
            categories {
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`
